<template>
  <v-container fluid>
    <v-card-title>
      공지사항
      <v-spacer />
      <v-btn outlined to="/notice/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="listNotice"
        :items-per-page="offset"
        sort-by="id"
        sort-desc
        show-select
        hide-default-footer
        @click:row="
          (row) => {
            $router.push(`${$route.path}/${row.id}`)
          }
        "
      >
        <template v-slot:item.createdAt="{ item }">
          {{ day(item.createdAt) }}
        </template>
        <template v-slot:item.publishedAt="{ item }">
          {{ day(item.publishedAt) }}
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteNotice">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-col cols="1">
              <v-select v-model="offset" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details @change="changeOffset" />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="pageNumber" total-visible="13" :length="length" circle color="#ffd600" @input="getListNotice" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import { day } from '@/helper'

export default defineComponent({
  setup(props, { root, emit }) {
    const state = reactive({
      listNotice: [],
      length: 1,
      pageNumber: 1,
      selected: [],
      offset: 15,
      headers: [
        { text: 'ID', value: 'id' },
        { text: '제목', value: 'title' },
        { text: '생성일시', value: 'createdAt' },
        { text: '발행일시', value: 'publishedAt' },
      ],
    })

    const getListNotice = async () => {
      try {
        const listNotice = await root.$store.dispatch('notice/getListNotice', {
          offset: state.offset,
          pageNumber: state.pageNumber,
        })

        state.listNotice = listNotice.data

        state.length = Math.ceil(listNotice.totalCount / state.offset)
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const refresh = () => {
      getListNotice()
      emit('refresh')
    }

    const deleteNotice = async () => {
      if (!confirm(`${state.selected.length}개의 공지사항을 삭제합니다. 공지사항 삭제 후 되돌릴 수 없습니다.`)) return

      try {
        for (const e of state.selected) {
          await root.$store.dispatch('notice/deleteNotice', {
            id: Number(e.id),
          })
        }
        alert(`공지사항 ${state.selected.length}개가 정상적으로 삭제되었습니다.`)
        state.selected = []
      } catch (err) {
        console.error(err)
        alert(err)
      }

      getListNotice()
    }

    const changeOffset = () => {
      state.pageNumber = 1

      getListNotice()
    }

    onBeforeMount(() => {
      getListNotice()
    })

    return {
      ...toRefs(state),
      getListNotice,
      refresh,
      deleteNotice,
      changeOffset,
      day,
    }
  },
})
</script>
